import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['target']

  add(event) {
    event.preventDefault()

    let content = this.template.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    this.targetTarget.insertAdjacentHTML('beforeend', content)
  }

  remove(event) {
    event.preventDefault()

    let wrapper = event.target.closest("nested-field-container")

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'

      let input = wrapper.querySelector("input[name*='_destroy']")
      input.value = '1'
    }
  }

  get template() {
    return this.element.querySelector("template")
  }
}