import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "field" ]
  static values = { key: String, ttl: Number }

  connect() {
    let json = localStorage.getItem(this.keyValue)
    if (json == null) {
      return
    }

    let storedData = JSON.parse(json)
    if (this.now.getTime() > storedData.expiry) {
      localStorage.removeItem(this.keyValue)
    } else {
      this.fieldTarget.value = storedData.value
    }
  }

  save() {
    let storageData = {
      value: this.fieldTarget.value,
      expiry: this.now.getTime() + (this.ttlValue * 60000)
    }

    localStorage.setItem(this.keyValue, JSON.stringify(storageData))
  }

  get now() {
    return new Date()
  }
}