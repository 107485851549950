export class Toast extends HTMLElement {
  connectedCallback() {
    this.show()
  }

  show() {
    this.isVisible = true

    if (this.isDismissible) {
      this.addEventListener("click", () => {
        this.hide(0)
      });

    } else {
      this.hide(5000)
    }
  }

  hide(delay) {
    setTimeout(() => {
      this.isVisible = false
    }, delay)

    setTimeout(() => {
      this.remove();
    }, delay + 1000)
  }

  get isVisible() {
    return this.getAttribute("am-visible") || false
  }

  set isVisible(visible) {
    this.setAttribute("am-visible", visible)
  }

  get isDismissible() {
    return this.getAttribute("am-dismissible") != null
  }
}
