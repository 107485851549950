import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['searchBar']

  performSearch() {
    if (this.searchBarTarget.value.length >= 3 || this.searchBarTarget.value.length == 0) {
      this.element.requestSubmit()
    }
  }
}